import { Injectable, Inject } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { OAuthApiService } from "./oauth.service";
import { CookieService } from "ngx-cookie-service";
import { ChildCareProvider } from "../models/child-care-provider";
import { HttpClient } from "@angular/common/http";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  private _baseUrl: string;
  private currentUserSubject = new BehaviorSubject<User>(null);
  private currentChildCare = new BehaviorSubject<ChildCareProvider>(null);
  private isImpersonation = new BehaviorSubject<boolean>(false);
  private isAdminEditing = new BehaviorSubject<boolean>(false);
  public isImpersonationObservable: Observable<boolean>;
  public isAdminEditingObservable: Observable<boolean>;
  public currentUser: Observable<User>;
  public userChildCaresSubject = new BehaviorSubject<ChildCareProvider[]>([]);

  public unreadMessages: any[];
  // public childCareHours = new BehaviorSubject<ChildCareProvider>(null);

  constructor(
    @Inject('BASE_URL') private baseUrl: string,
    private oauth: OAuthApiService,
    private cookieService: CookieService,
    private http: HttpClient
  ) {
    if (cookieService.get("currentUser")) {
      this.currentUserSubject = new BehaviorSubject<User>(
        JSON.parse(cookieService.get("currentUser"))
      );
      this.currentUser = this.currentUserSubject.asObservable();
      this.isImpersonationObservable = this.isImpersonation.asObservable();
      this.isAdminEditingObservable = this.isAdminEditing.asObservable();
      this.userChildCaresSubject = new BehaviorSubject<ChildCareProvider[]>(JSON.parse(localStorage.getItem("userProviders")));
      if (this.userChildCaresSubject.value)
        this.currentChildCare.next(this.userChildCaresSubject.value[0]);

    }
    else {
      this.currentUserSubject = new BehaviorSubject<User>(null);
      this.userChildCaresSubject = new BehaviorSubject<ChildCareProvider[]>(null);
      this.currentUser = this.currentUserSubject.asObservable();
      this.isImpersonationObservable = this.isImpersonation.asObservable();
      this.isAdminEditingObservable = this.isAdminEditing.asObservable();
      this.unreadMessages = [];
    }
    this._baseUrl = baseUrl;
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public setCurrentUser(user: User) {
    return this.currentUserSubject.next(user);
  }

  exchangeIdToken(user: string, password: string) {
    //Call to API here
    let request = new OAuthTokenExchangeRequest();
    request.user = user;
    request.password = password;
    
    return this.oauth.exchangeToken(request).pipe(
      map((oAuthResponse) => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        if (oAuthResponse.result === "reset" || oAuthResponse.result === "error") return oAuthResponse;

        this.currentChildCare.next(new ChildCareProvider());
        //this.getChildCares(oAuthResponse.user.id);

        const dateNow = new Date();
        dateNow.setHours(dateNow.getHours() + 1);
        this.cookieService.set('isLoggedIn', 'true', dateNow);


        let user = new User();
        user = oAuthResponse.user;
        user.token = oAuthResponse.accessToken;

        this.cookieService.set("currentUser", JSON.stringify(user), dateNow);

        if (!this.currentUserSubject) {
          this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(this.cookieService.get("currentUser")));
          this.currentUser = this.currentUserSubject.asObservable();
        }
        else {
          this.currentUserSubject.next(user);
        }
        //if (user && user.userType === 1) {
        //  this.setUnreadMessages(user)
        //}
        //this.getChildCares(user.id);
        return oAuthResponse;
      })
    );
  }

  logout() {
    localStorage.removeItem("userProviders");
    localStorage.removeItem('isImpersonation');
    localStorage.removeItem("adminUser");
    localStorage.removeItem("unreadMessages");
    this.cookieService.deleteAll();
    this.isImpersonation.next(false);
    this.isAdminEditing.next(false);
    this.currentUserSubject.next(null);
    this.userChildCaresSubject.next(null);
    //this.childCareHours.next(null);
  }

  setUnreadMessages(user) {
    localStorage.removeItem("unreadMessages");

    this.setActiveUnreadMessages(user.id).subscribe(
      res => {
        this.unreadMessages = res
        localStorage.setItem("unreadMessages", JSON.stringify(res));
      }
    )
  }
  setActiveUnreadMessages(provUsrId) {
    return this.http.get<any[]>(`${this._baseUrl}api/providermessage/active-unread-messages/${provUsrId}`);
  }
  getUnreadMessages() {
    console.log('getUnreadMessages()', this.unreadMessages)
    this.unreadMessages = JSON.parse(localStorage.getItem("unreadMessages"));
    return this.unreadMessages;
  }
  setCurrentChildCareValue(childCare: ChildCareProvider) {
    this.currentChildCare.next(childCare);
  }

  setImpersonation(val: boolean) {
    this.isImpersonation.next(val);
  }
  setIsAdminEditing(val: boolean) {
    this.isAdminEditing.next(val);
  }


  getIsAdminEditing(): boolean {
    return this.isAdminEditing.value;
  }

  getIsImpersonation(): boolean {
    return this.isImpersonation.value;
  }

  getChildCares(id: number) {
    this.http.get<ChildCareProvider[]>(`${this._baseUrl}api/childcare/getAllByUser/${id}`).subscribe(result => {
      this.userChildCaresSubject.next(result);
      this.currentChildCare.next(this.userChildCaresSubject.value[0]);
      const dateNow = new Date();
      dateNow.setHours(dateNow.getHours() + 1);
      localStorage.setItem("userProviders", JSON.stringify(result));
      this.cookieService.set("userProvider", JSON.stringify(this.userChildCaresSubject.value[0]), dateNow, '/', 'localhost', false, 'Lax');
    });
  }

  getChildCaresWhitInitChildCare(id: number, current: ChildCareProvider) {
    this.http.get<ChildCareProvider[]>(`${this._baseUrl}api/childcare/getAllByUser/${id}`).subscribe(result => {
      this.userChildCaresSubject.next(result);
      this.currentChildCare.next(current);
      const dateNow = new Date();
      dateNow.setHours(dateNow.getHours() + 1);
      localStorage.setItem("userProviders", JSON.stringify(result));
      this.cookieService.set("userProvider", JSON.stringify(current), dateNow, '/', 'localhost', false, 'Lax');
    });
  }

  getProfileInfo(id: number, current: ChildCareProvider) {
    this.http.get<ChildCareProvider[]>(`${this._baseUrl}api/childcare/getProfileInfo/${id}`).subscribe(result => {
      this.userChildCaresSubject.next(result);
      this.currentChildCare.next(current);
      const dateNow = new Date();
      dateNow.setHours(dateNow.getHours() + 1);
      localStorage.setItem("userProviders", JSON.stringify(result));
      this.cookieService.set("userProvider", JSON.stringify(current), dateNow, '/', 'localhost', false, 'Lax');
    });
  }

  getProfileInfoByProviderId(token: string) {
    return this.http.get<ChildCareProvider>(`${this._baseUrl}api/childcare/getProfileInfo/${token}`);
  }
  //getChildCareByLicense(license: string) {
  //  this.http.get<ChildCareProvider>(`${this._baseUrl}api/childcare/profile/ChildCareHour/${license}`).subscribe(result => {
  //   this.childCareHours.next(result);      
  //  });
  //}
  //async getChildCareByLicense2(license: string) {
  //  const tmpResult = await this.http.get<ChildCareProvider>(`${this._baseUrl}api/childcare/profile/ChildCareHour/${license}`).toPromise();
  //  this.setCurrentChildCareValue(tmpResult);
  //  this.childCareHours.next(tmpResult);     
  //  return tmpResult;
  //}

  getCurrentChildCare() {
    return this.currentChildCare.value;

    //if (this.currentChildCare?.value?.standarizedHours)
    //  return this.currentChildCare.value;
    //else
    //  return this.childCareHours.value;
  }

  getCurrentChildCareObservable() {
    return this.currentChildCare.asObservable();
  }

  selectChildCare(childCare: ChildCareProvider) {
    this.currentChildCare.next(childCare);
  }

  getUserChildCares(): Observable<ChildCareProvider[]> {
    return this.userChildCaresSubject.asObservable();
  }

  clearChildCares() {
    this.userChildCaresSubject.next(null);
  }
}

//TODO: move to another file
export class User {
  public id: number;
  public firstName: string;
  public lastName: string;
  public token: string;
  public userType: number;
}

export class OAuthTokenExchangeRequest {
  user: string;
  password: string;  
}
